import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment PropertyCard on WpProperty {
      databaseId
      id
      slug
      title
      date
      uri
      propertyMeta {
        id
        address {
          unitNumber
          street
          streetNumber
          suburb {
            name
            postcode
          }
        }
        addressVisibility
        available
        carports
        garages
        openSpaces
        bed
        bath
        landAreaValue
        landAreaUnits
        displayPrice
        referenceID
        displayAddress
        class
        type
        status
        searchPrice
        isLeased
      }
      externalLinks {
        type
        url
      }
      images {
        url
        thumb_1024
        thumb_180
      }
    }
    fragment RentalCard on WpRental {
      databaseId
      id
      slug
      title
      date
      uri
      propertyMeta {
        id
        address {
          unitNumber
          street
          streetNumber
          suburb {
            name
            postcode
          }
        }
        addressVisibility
        available
        carports
        garages
        openSpaces
        bed
        bath
        landAreaValue
        landAreaUnits
        displayPrice
        displayAddress
        class
        type
        status
        searchPrice
        isLeased
      }
      images {
        url
        thumb_1024
        thumb_180
      }
      externalLinks {
        type
        url
      }
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      content
      excerpt
      date(formatString: "ddd DD MMMM")
    }
    fragment TeamCard on WpTeam {
      id
      uri
      title
      acf {
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
        position
        phone
        email
      }
      info {
        id
        firstName
        lastName
        staffTypeId
        email
        position
        role
        thumbnail
      }
      phoneNumbers {
        number
        type
      }
    }
    fragment CaseStudyCard on WpCase {
      id
      uri
      slug
      title
      acf {
        type
        agents {
          ... on WpTeam {
            id
          }
        }
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1600
              )
            }
          }
        }
        beforeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
        }
        afterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
        }
        meta {
          bed
          bath
          car
          landSize
        }
        address
        suburb
        postcode
        excerpt
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteSettings {
          acf {
            menu {
              link {
                url
                title
              }
              subMenu {
                link {
                  url
                  title
                }
              }
            }
            menus {
              title
              menu {
                link {
                  url
                  title
                }
              }
            }
            email {
              url
              title
            }
            phone {
              url
              title
            }
            address
          }
        }
      }
    }
  `)
}

export default useLayoutQuery